<template>
  <div class="email-confirmation-container" v-show="this.is_shown">
    <div class="top-title">Email Confirmation Required</div>
    <div class="top-content">
      Email not verified. Check spam or <a href="#" @click="this.resendVerifyEmail">resend verify email.</a>

      <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>{{regSucess}}</b-alert>
      <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}</b-alert>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ProfileService from "@/apis/profile-services"
import UserService from "@/apis/users-services"

export default {
  name: "email-confirmation",
  data(){
    return {
      is_shown: false,
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      regSucess: null,
      registerSuccess: false,
    }
  },
  methods: {
    resendVerifyEmail(){
      this.$isLoading(true); // show loading screen
      this.tryingToRegister = true;
      // Reset the regError if it existed.
      this.regError = null;
      this.isRegisterError = false;
      UserService.resendVerifyEmail()
          .then((response) => {
            const {status, message} = response;
            if (status === "errors") {
              this.regError = message;
              this.tryingToRegister = false;
              this.isRegisterError = true;
              this.$router.push({
                path: '/locked'
              })
            }else {
              this.regSucess = message;
              this.tryingToRegister = false;
              this.registerSuccess = true;
            }
          }).catch((error) => {
            console.log(error)
          }).finally(() => {
            this.$isLoading(false);
          })
    }
  },
  computed: {
    ...mapGetters('auth', ['currentUser', 'loggedIn'])
  },
  mounted() {
    const jwt = localStorage.getItem('jwt') ?? "";
    if (jwt !== "")
      ProfileService.profile(jwt).then((data) => {
        const user = data.data.user;
        this.is_shown = user.email_verified !== "verified";
      });
  },
  beforeMount() {
    // if (this.currentUser == null)
    //   this.is_shown = false;
    // else {
    //   if (this.currentUser.email_verified == null) this.is_shown = true;
    //   if (this.currentUser.email_verified === '') this.is_shown = true;
    // }
  },
}
</script>

<style scoped></style>