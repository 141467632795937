import {defineStore} from 'pinia';

export const alertStore = defineStore({
    id: 'alertStore',
    state: () => ({
        message: null,
        errors: null,
        color: 'success'
    }),
    actions: {
        setMessage(message) {
            this.message = message;
        },
        setErrors(errors) {
            this.errors = errors;
        },
        setColor(color) {
            this.color = color;
        },
        setAlert(data) {
            this.message = data.message || null;
            this.errors = data.errors || null;
            this.color = data.color || null;
        },
        resetState() {
            this.message = null;
            this.errors = null;
            this.color = 'success';
        }
    }
});