<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!--          <button type="button" class="-->
          <!--              btn btn-sm-->
          <!--              px-3-->
          <!--              fs-16-->
          <!--              header-item-->
          <!--              vertical-menu-btn-->
          <!--              topnav-hamburger-->
          <!--            " id="topnav-hamburger-icon">-->
          <!--            <span class="hamburger-icon">-->
          <!--              <span></span>-->
          <!--              <span></span>-->
          <!--              <span></span>-->
          <!--            </span>-->
          <!--          </button>-->
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo" id="user-panel-logo">
            <router-link to="/" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="@/assets/images/logo-sm.png" alt="" height="40"/>
                        </span>
              <span class="logo-lg">
                            <img src="@/assets/images/logo-dark.png" alt="" height="55"/>
                        </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="@/assets/images/logo-sm.png" alt="" height="22"/>
                        </span>
              <span class="logo-lg">
                            <img src="@/assets/images/logo-light.png" alt="" height="55"/>
                        </span>
            </router-link>
          </div>
        </div>
        <div class="d-flex align-items-center">
<!--          <span class="language">{{ $t("language") }}</span>-->
<!--          <div class="dropdown ms-1 topbar-head-dropdown header-item">-->
<!--            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"-->
<!--                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--              <img id="header-lang-img" src="@/assets/images/flags/us.svg" alt="Header Language" height="24"-->
<!--                   class="rounded"/>-->
<!--            </button>-->
<!--            <div class="dropdown-menu dropdown-menu-end">-->
<!--              &lt;!&ndash; item&ndash;&gt;-->
<!--              <a href="javascript:void(0);" v-for="(entry, i) in languages" :key="`Lang${i}`" :value="entry"-->
<!--                 @click="setLanguage(entry.language, entry.title, entry.flag)"-->
<!--                 :class="{ active: lan === entry.language }" class="dropdown-item notify-item language py-2"-->
<!--                 data-lang="en" title="English">-->
<!--                <img :src="entry.flag" alt="user-image" class="me-2 rounded" height="18"/>-->
<!--                <span class="align-middle">{{ entry.title }}</span>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->

          <div class="ms-1 header-item d-none d-sm-flex">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                    data-toggle="fullscreen" @click="initFullScreen">
              <i class="bx bx-fullscreen fs-22"></i>
            </button>
          </div>


          <div class="dropdown ms-sm-3 header-item topbar-user" v-if="username">
            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
              <span class="d-flex align-items-center">
                <img class="rounded-circle header-profile-user" :src="this.avatar"
                     alt="Header Avatar"/>
                <span class="text-start ms-xl-2">
                  <span class=" d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{ username }}</span>
                </span>
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
<!--              <router-link class="dropdown-item" to="/profile">-->
<!--                <i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>-->
<!--                <span class="align-middle">Profile</span>-->
<!--              </router-link>-->
<!--              <div class="dropdown-divider"></div>-->
              <!--                  <router-link class="dropdown-item" to="/auth/lockscreen-basic"><i-->
              <!--                      class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>-->
              <!--                    <span class="align-middle">Lock screen</span>-->
              <!--                  </router-link>-->
              <a class="dropdown-item" href="#" @click="logout">
                <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle" data-key="t-logout">{{ $t("logout") }}</span>
              </a>
            </div>
          </div>

<!--          <div class="header-item d-sm-flex" v-else>-->
<!--            <RouterLink type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" to="/login" :title="$t('sign_in')">-->
<!--              <i class="bx bx-log-in fs-22"></i>-->
<!--            </RouterLink>-->
<!--            <RouterLink type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"-->
<!--                        :title="$t('sign_up')" to="/signup">-->
<!--              <i class="bx bx-log-out fs-22"></i>-->
<!--            </RouterLink>-->
<!--          </div>-->

        </div>
      </div>
    </div>
  </header>
</template>

<script>
import i18n from "../i18n";
import {mapGetters} from "vuex"
import {
  authMethods,
} from "@/state/helpers";


/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      username: "",
      public_path: process.env.VUE_APP_URL,
      avatar: ["@/assets/images/users/default.png"],
      languages: [{
        flag: require("@/assets/images/flags/us.svg"),
        language: "en",
        title: "English",
      },
        {
          flag: require("@/assets/images/flags/french.svg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.svg"),
          language: "sp",
          title: "Spanish",
        },
        {
          flag: require("@/assets/images/flags/italy.svg"),
          language: "it",
          title: "Italiana",
        },
        {
          flag: require("@/assets/images/flags/germany.svg"),
          language: "gr",
          title: "Deutsche",
        },
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      myVar: 1,

    };
  },
  components: {},

  methods: {
    ...authMethods,
    logout() {
      this.logOut();
      this.$router.push({
        name: "login"
      });
    },
    isCustomDropdown() {
    },
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (document.documentElement.getAttribute("data-layout") === "horizontal") {
        document.body.classList.contains("menu") ?
            document.body.classList.remove("menu") :
            document.body.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm" ?
              document.documentElement.setAttribute("data-sidebar-size", "") :
              document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg" ?
              document.documentElement.setAttribute("data-sidebar-size", "sm") :
              document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel") ?
            document.body.classList.remove("twocolumn-panel") :
            document.body.classList.add("twocolumn-panel");
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (!document.fullscreenElement &&
          /* alternative standard method */
          !document.mozFullScreenElement &&
          !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      localStorage.setItem('lang', JSON.stringify({locale, country, flag}));
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      // document.getElementById("header-lang-img").setAttribute("src", flag);
      i18n.global.locale = locale;
    },
    toggleDarkMode() {
      if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
        document.documentElement.setAttribute("data-layout-mode", "light");
      } else {
        document.documentElement.setAttribute("data-layout-mode", "dark");
      }
    },
    isValidUrl : urlString=> {
      var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
          '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
      return !!urlPattern.test(urlString);
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser"])
  },
  mounted() {
    const lang = localStorage.getItem('lang');
    if (lang !== null){
      const {flag, country, locale} = JSON.parse(lang);
      this.setLanguage(locale, country, flag);
    }

    document.addEventListener("scroll", function () {
      var pageTopbar = document.getElementById("page-topbar");
      if (pageTopbar) {
        document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ?
            pageTopbar.classList.add("topbar-shadow") :
            pageTopbar.classList.remove("topbar-shadow");
      }
    });

    if (document.getElementById("topnav-hamburger-icon")) {
      document
          .getElementById("topnav-hamburger-icon")
          .addEventListener("click", this.toggleHamburgerMenu);
    }
  },
  beforeMount() {

  },
  watch:{
    currentUser : {
      handler(newVal){
        if (newVal) {
          this.username = newVal.name ? newVal.name : newVal.email;
          if (!this.isValidUrl(newVal.photo)) {
            this.avatar = newVal.photo;
          }else {
            this.avatar = this.public_path + "/images/users/" + newVal.photo;
          }
        }else {
          this.username = "";
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
