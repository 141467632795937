<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          {{new Date().getFullYear()}} © {{$t("ptr_selfservice")}}.
        </div>
      </div>
    </div>
  </footer>
</template>

<style>
  footer .container{
    height: 100%;
    padding: 0;
    background:  url("~@/assets/images/footer.png") no-repeat right;
    display: flex;
    align-items: center; /* Vertical center alignment */
    justify-content: center; /* Horizontal center alignment */
  }
  .footer{
    padding: 0;
  }
</style>

