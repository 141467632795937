<template>
  <div class="custom-toast toast fade show" v-if="alert.message" role="alert" aria-live="assertive"
       data-bs-autohide="false" aria-atomic="true">
    <div class="toast-header">
      <img src="@/assets/images/logo-sm.png"
           class="rounded me-2" alt="..." height="20">
      <span class="fw-semibold me-auto">SelfService</span>
      <button type="button" class="btn-close" @click="alert.resetState"></button>
    </div>
    <div class="toast-body" :class="'alert-' + alert.color">
      {{ alert.message }}
    </div>
  </div>
</template>

<script setup>
import {useRoute} from 'vue-router';
import {onBeforeUnmount, watch} from 'vue'
import {alertStore} from "@/store/alert";

const alert = alertStore();
const route = useRoute();
onBeforeUnmount(() => {
  alert.resetState();
});

watch(() => route.params.id, () => {
  alert.resetState();
})
</script>

<style lang="scss" scoped>
$main-theme-primary-color: #FF5C16;
.toast-header {
  background: $main-theme-primary-color;
  color: white;
}

.custom-toast {
  position: fixed;
  top: 87px;
  right: 1px;
  z-index: 99999
}
</style>